<template class="img-body">
  <div class="img-body p-0">
    <div class="row px-0 w-100 m-0">
      <div class="col-lg-7 col-sm-0 px-0">
        <div>
          <img
            src="/assets/img/LogoMaqBranca.png"
            width="180px"
            class="mt-3 ml-3"
          />
        </div>
        <div class="coluna_maior ml-5">
          <div>
            <h1 class="justify-content">
              Cockpit
              <br />Maq-Larem
            </h1>
            <h6>Hora de assumir o controle da sua rotina!</h6>
          </div>
          <form>
            <b-form-group class="">
              <input
                id="email"
                @keydown.enter="login"
                class="input w-100 p-1"
                type="email"
                placeholder="Email"
              />
            </b-form-group>
            <b-form-group>
              <input
                :type="passwordFieldType"
                @keydown.enter="login"
                placeholder="Senha"
                class="input w-100"
                id="password"
              />
            </b-form-group>
            <i
              @click="switchVisibility"
              v-on:click="switchEye()"
              class="material-icons-outlined field-icon toggle-password"
              id="eyechange"
              >visibility_off
            </i>
            <div>
              <b-btn
                class="w-100 mt-3 mb-3"
                variant="light"
                @click="login"
                style="font-size: 16px; color: #a1000c; font-weight: bold"
                >Acessar</b-btn
              >
            </div>
          </form>
        </div>
      </div>
      <div class="col-lg-5 col-sm-12 coluna_menor px-0">
        <div class="contaiflut px-4"></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass">
@import "~/public/assets/styles/scss/pages/authentication.scss"
.field-icon
  color: white
  float: right
  margin-left: -25px
  margin-top: -41.5px
  margin-right: 10px
  position: relative
  z-index: 2
  cursor: pointer
</style>

<script>
import AuthModel from "../../models/Auth/AuthModel";
import AuthService from "../../services/AuthService/AuthService";
import { getCookie } from "../../utils/utils";
import { router } from "../../routers/router";

export default {
  name: "Auth",
  data() {
    return {
      passwordFieldType: "password",
    };
  },

  created: function () {
    new AuthService().refreshToken();
    if (getCookie("refresh")) {
      return router.push("/");
    }
  },
  methods: {
    login() {
      let email = document.getElementById("email").value;
      let password = document.getElementById("password").value;

      let auth = new AuthModel(email, password);

      new AuthService().login(auth);
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },

    switchEye() {
      let element = document.getElementById("eyechange");
      if (this.passwordFieldType === "password") {
        element.innerHTML = "visibility_off";
      } else {
        element.innerHTML = "visibility";
      }
    },
  },
};
</script>
